<template>
  <div class="content p-0" style="background-color: #cbd8e1; height: 100vh">
    <div class="login-wrapper">
      <div class="row m-0">
        <div
          class="col-lg-6 h-100-vh side-banner"
          style="
            background-image: url('https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/e7f911620268d42205f453d67ad6c80c.jpg');
            background-position: center center;
            background-size: contain;
            display: flex;
            justify-content: center;
          "
        >
        </div>
        <div class="col-lg-6 site-content">
          <div class="content" style="padding-top: 0px">
            <div class="row">
              <div class="col-lg-10 mx-auto">
                <div class="text-center mb-4">
                  <h2>Crea tu cuenta</h2>
                  <p style="font-weight: 600">
                    Completa tus datos y sigue las instrucciones enviadas a tu correo
                    electronico.
                  </p>
                </div>
                <div class="card shadow border-0">
                  <div class="card-body px-lg-5 py-lg-5">
                    <v-form action class="form" ref="form" v-model="valid">
                      <v-text-field
                        v-model="model.name"
                        :rules="[rules.required]"
                        prepend-inner-icon="mdi-account-star"
                        label="  Nombres"
                        hint=""
                        counter
                      ></v-text-field>
                      <v-text-field
                        v-model="model.lastname"
                        :rules="[rules.required]"
                        prepend-inner-icon="mdi-account-star"
                        label="  Apellidos"
                        hint=""
                        counter
                      ></v-text-field>
                      <v-text-field
                        v-model="model.email"
                        :rules="[rules.required, rules.email]"
                        prepend-inner-icon="mdi-email-lock"
                        label="  Correo electrónico"
                        hint=""
                        counter
                      ></v-text-field>
                      <v-text-field
                        v-model="model.password"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show ? 'text' : 'password'"
                        prepend-inner-icon="mdi-lock"
                        label="  Contraseña"
                        hint=""
                        counter
                        @click:append="show = !show"
                      ></v-text-field>
                      <v-text-field
                        v-model="model.password_confirmation"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show ? 'text' : 'password'"
                        prepend-inner-icon="mdi-lock"
                        label="  Confirmar contraseña"
                        hint=""
                        counter
                        @click:append="show = !show"
                      ></v-text-field>
                      <div class="text-center">
                        <button
                          type="button"
                          :disabled="!valid"
                          @click.prevent="validate"
                          class="btn btn-primary mt-2 mb-3"
                          style="
                            color: #fff;
                            background-color: #009ada;
                            border-color: #009ada;
                          "
                        >
                          Registrarse</button
                        ><br />
                        <button
                          title="Ir a la pagina principal"
                          style="
                            color: #fff;
                            background-color: #009ada;
                            border-color: #009ada;
                          "
                          @click.prevent="$router.push('/')"
                          class="btn btn-primary"
                        >
                          <v-icon dark style="margin: 0px">
                            mdi-arrow-collapse-right </v-icon
                          ><v-icon dark style="margin: 0px"> mdi-home </v-icon>
                        </button>
                      </div>
                    </v-form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data: () => ({
      error: '',
      valid: true,
      dialog: false,
      loading: false,
      show: false,
      model: {
        name: '',
        nameRules: [(v) => !!v || 'Tu nombre es requerido'],
        lastname: '',
        type: 0,
        lastnameRules: [(v) => !!v || 'Tu apellido es requerido'],
        email: '',
        emailRules: [
          (v) => !!v || 'Correo electrónico es requerido',
          (v) => /.+@.+\..+/.test(v) || 'El formato de correo electrónico es invalido',
        ],
        password: '',
        passwordRules: [(v) => !!v || 'La contraseña es requerida'],
        password_confirmation: '',
        password_confirmationRules: [(v) => !!v || 'La contraseña es requerida'],
      },
      rules: {
        required: (value) => !!value || 'Requerido.',
        min: (v) => v.length >= 6 || 'Escriba por lo menos 6 caracteres',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'El formato de correo electrónico inválido'
        },
      },
      lazy: false,
    }),
    watch: {
      countryId(newValue, oldVAlue) {},
    },
    computed: {
      countryId() {
        if (localStorage.getItem('selectedCountry')) {
          return JSON.parse(localStorage.getItem('selectedCountry')).id
        } else {
          return null
        }
      },
    },
    created() {},
    methods: {
      validate() {
        if (this.$refs.form.validate()) {
          this.signup()
        }
      },
      signup() {
        axios
          .post(process.env.VUE_APP_API_DIRECTORY + 'auth/signup', {
            country_id: this.countryId,
            name: this.model.name,
            lastname: this.model.lastname,
            email: this.model.email,
            type: 0,
            password: this.model.password,
            password_confirmation: this.model.password_confirmation,
          })
          .then((response) => {
            this.$swal({
              title: `¡Bienvenido!`,
              html: `Creaste tu cuenta satisfactoriamente.<br> Hemos enviado a tu correo electrónico un <strong>código para activar tu cuenta.</strong>`,
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#003a56',
              confirmButtonText: 'Aceptar',
            })
            this.$router.push('/session/activate-account')
            this.loading = false
          })
      },
    },
  }
</script>
<style lang="scss">
  .col-lg-4,
  .col-md-6,
  .col-sm-12 {
    padding: 0px auto !important;
  }
  .theme--light.v-input {
    color: rgba(0, 0, 0, 0.87);
    padding: 0px;
    margin: 10px auto;
  }
  .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
    display: flex;
    flex: 1 1 auto;
    position: relative;
    width: 100%;
  }
  .swal2-content {
    max-height: 250px;
    min-height: auto;
    overflow-y: auto;
  }
  dd {
    font-size: 15px;
  }
  dt {
    font-size: 16px;
  }
  .swal2-icon {
    margin: auto 1.875em;
  }
  .swal2-header {
    font-size: 14px;
  }
  .swal2-actions {
    margin: 0px;
  }
</style>
